<template>
  <div class="main">
    <div class="nav-1 f-row">
      <div class="image">
        <img
          :src="'/com/file/fileDownLoad?filePath=' + formdata.headImage"
          alt=""
          :onerror="smallImgError"
        />
      </div>
      <div class="f-se f-col">
        <h2 style="margin-bottom: 10px">{{ formdata.expertName }}</h2>
        <!-- <span>{{ formdata.introduce }} </span> -->
        {{ formdata.duty }}
        <div></div>
      </div>
    </div>
    <!-- ces2 -->

    <div class="nav-2">
      <Title title="服务案例"></Title>
      <!-- 轮播 -->
      <div class="outbox">
        <div class="swiper">
          <div class="swiper-wrapper">
            <template v-if="formdata.stuList && formdata.stuList.length > 0">
              <el-card
                shadow="hover"
                class="swiper-slide"
                v-for="(item, index) in formdata.stuList"
                :key="index"
              >
                <h2>{{ item.studentName }}</h2>
                <p style="margin-top: 20px; margin-bottom: 15px">
                  {{ item.graduateYear }}年毕业于{{ item.schoolName }}
                </p>
                <p style="margin-bottom: 15px">高考成绩：{{ item.score }}</p>
                <p>录取于{{ item.actualCollege }}</p>
              </el-card>
            </template>
            <!-- <template v-else>
              <el-card
                shadow="hover"
                class="swiper-slide"
                v-for="item in 8"
                :key="item"
              >
                <h2 style="">刘同学</h2>
                <p style="margin-top: 20px; margin-bottom: 15px">
                  2020年毕业于重庆第20中学
                </p>
                <p style="margin-bottom: 15px">2020年毕业于重庆第20中学</p>
                <p>2020年毕业于重庆第20中学</p>
              </el-card>
            </template> -->
          </div>

          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>

          <!-- <div class="swiper-scrollbar"></div> -->
        </div>

        <div class="swiper-button-prev swiper-b">
          <img src="../../assets/firstPage/7.png" alt="" />
        </div>
        <div class="swiper-button-next swiper-b">
          <img src="../../assets/firstPage/8.png" alt="" />
        </div>
      </div>

      <!-- <div class="outbox">
        <swiper
          previous-margin="50px"
          style="width: 90%"
          :slides-per-view="8"
          :options="swiperOption"
          :space-between="50"
        >
          <swiper-slide class="tttt" v-for="i in 10" :key="i">
         
          </swiper-slide>
        </swiper>
      </div> -->
    </div>
    <!-- 专家介绍 -->
    <div class="nav-3">
      <Title title="专家介绍"></Title>
      <div class="footer" v-html="formdata.introduce"></div>
    </div>
  </div>
</template>
<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// import "swiper/css/swiper.css";
import { getExpertDetail } from "@/api/exports";
import Title from "@/components/Title";
export default {
  name: "ExpertDetail",
  components: { Title },
  data() {
    return {
      records: [
        {
          expertName: 11,
        },
        {
          expertName: 22,
        },
        {
          expertName: 33,
        },
        {
          expertName: 33,
        },
      ],
      formdata: {},
      active: "1",
    };
  },
  mounted() {
    this.swiper(4, true, ".swiper");

    this.getDetail();
  },
  methods: {
    async getDetail() {
      let res = await getExpertDetail({ expertId: this.$route.query.id });
      if (res.code == 1) {
        this.formdata = res.data;
      }
    },

    swiper(val, bol, swiper) {
      var mySwiper = new Swiper(swiper, {
        loop: true, // 循环模式选项
        slidesPerView: val || "auto",
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        // 如果需要分页器
        // pagination: {
        //   el: ".swiper-pagination",
        // },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // slidesOffsetBefore: -20,
        spaceBetween: 60,
        // 如果需要滚动条
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 1200px;
  margin: 0 auto;
}
.nav-1 {
  margin: 30px 0;
  .image {
    border-radius: 50%;
    margin-right: 10px;
    img {
      object-fit: cover;
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }
  }
}
.nav-2 {
  padding: 30px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.nav-3 {
  margin-bottom: 30px;
}

.outbox {
  .swiper {
    width: 100% !important;
    .swiper-wrapper {
      width: 100% !important;
      .swiper-slide {
        // width: 250px !important;
        width: 20% !important;
      }
    }
  }

  .swiper-slide {
    cursor: pointer;
    // border: 1px solid gray;
    // width: 20% !important;
    img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
    }
  }
  position: relative;
  padding: 0 50px;
  .swiper-button-prev {
    // background: url("../../assets/firstPage/7.png");
    // color: white;
  }
}
.swiper {
  box-sizing: border-box;
  width: 100% !important;
  .swiper-wrapper {
    width: 100% !important;
    .swiper-slide {
      // width: 250px !important;
      width: 20% !important;
    }
  }
}
.footer {
  // line-height: 1.5;
  line-height: 24px;
}
.swiper {
  position: initial;
}
.swiper-button-prev:after {
  content: "";
}
.swiper-button-next:after {
  content: "";
}
</style>