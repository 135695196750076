import axios from '@/util/request'
//高考动态

// GET /mobile/expert-info/getExpertDetail
// 查看专家详情
export const getExpertDetail = (params) => {
        return axios({
            url: '/mobile/expert-info/getExpertDetail',
            method: 'get',
            params
        })

    }
    // POST /mobile/expert-info/getExpertPage
    // 查看专家分页列表
export const getExpertPage = (data) => {
    return axios({
        url: '/mobile/expert-info/getExpertPage',
        method: 'post',
        data
    })

}