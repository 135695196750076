<template>
  <!-- 标题组件 -->
  <div class="title-bar">
    <div class="title-bar-left">
      <div class="left-line"></div>
      <div class="title-name">
        {{ title }}
        <!-- <span class="sub-title">{{ subTitle }}</span> -->
        <slot name="subTitle"></slot>
        <!-- 实际使用
          <template v-slot:subTitle>
            <span></span>   
          </template>
        -->
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: {
      type: String,
      default: "" //title名字
    },
    subTitle: {
      type: String,
      default: "" //title名字
    }
  }
};
</script>

<style lang="less" scoped>
.title-bar {
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  .title-bar-left {
    display: flex;
    justify-content: flex-start;
  }
  .left-line {
    width: 6px;
    height: 24px;
    background: #2e8cf0;
    border-radius: 0px 3px 3px 0px;
    margin-right: 10px;
  }
  .title-name {
    font-size: 18px;
    font-weight: bold;
    color: #666666;
  }
  .sub-title {
    font-size: 14px;
    color: #ff4e4e;
  }
}
</style>